import React, { createContext, useState, useContext } from 'react';

// Create the RewardContext
const RewardContext = createContext();

// SuccessProvider component that will wrap your app and provide context
export const RewardProvider = ({ children }) => {
    const [rewardModalOpen, setRewardModalOpen] = useState(false);
    const [rewardCallback, setRewardCallback] = useState({
        stripe: () => { },
        cgdx: () => { }
    });

    const openRewardModal = (callbacks) => {
        setRewardCallback({
            stripe: callbacks.stripe,
            cgdx: callbacks.cgdx
        });
        setRewardModalOpen(true);
    };

    const closeRewardModal = () => {
        setRewardCallback({
            stripe: () => { },
            cgdx: () => { }
        });
        setRewardModalOpen(false);
    }

    const claimReward = async (selectedPayment) => {
        try {
            if (typeof rewardCallback[selectedPayment] === "function") {
                await rewardCallback[selectedPayment]();
            } else {
                console.error(`Invalid payment method: ${selectedPayment}`);
            }
            closeRewardModal();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <RewardContext.Provider value={{ rewardModalOpen, openRewardModal, closeRewardModal, claimReward, setRewardCallback }}>
            {children}
        </RewardContext.Provider>
    );
};

// Custom hook to use the RewardContext
export const useReward = () => {
    return useContext(RewardContext);
};

export default RewardContext;
