import React, { useState, useEffect, useContext } from 'react';
import styled, { keyframes } from 'styled-components';

import { useAuth } from '../../context/AuthContext';
import { WalletContext } from '../../context/WalletContext';
import { useReward } from '../../context/RewardContext';
import { createStripeAccount } from '../../apis/StripeApi';

import MainButtonInvert from '../buttons/MainButtonInvert';

// Keyframes for animations
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;
const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;
const scaleIn = keyframes`
  from { opacity: 0; transform: scale(0.9); }
  to { opacity: 1; transform: scale(1); }
`;
const scaleOut = keyframes`
  from { opacity: 1; transform: scale(1); }
  to { opacity: 0; transform: scale(0.9); }
`;

// Styled components
const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  z-index: 101;
  animation: ${(props) => (props.animationType === 'in' ? fadeIn : fadeOut)} 0.3s ease-in-out forwards;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 400px;
  background: white;
  border-radius: 15px;
  box-shadow: 0 0 10px var(--primary-color);
  border: 2px solid var(--primary-color);
  padding: 20px;
  gap: 15px;
  animation: ${(props) => (props.animationType === 'in' ? scaleIn : scaleOut)} 0.3s ease-in-out forwards;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  h3 {
    font-size: 24px;
    font-family: 'Modernist', sans-serif;
    color: black;
    margin: 0;
  }
`;

const CloseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  text-align: center;

  p {
    font-size: 18px;
    font-weight: 600;
    color: black;
    margin: 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const PaymentSelection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;

  p {
    font-size: 20px;
    font-weight: 600;
    color: black;
    margin: 0;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background: black;
`;

const SelectPaymentButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  padding: 5px 10px;
  background: black;
  color: white;
  font-size: 20px;
  font-weight: 600;
  border-radius: 7px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: ${(props) => (props.selected ? '2px solid var(--primary-color)' : '2px solid black')};

  &:hover {
    border: 2px solid var(--primary-color);
  }
`;

const ModalEnd = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  min-height: 50px;
  margin-top: 10px;
  gap: 20px;
`;

const PaymentSelectionModal = () => {
  const [animationType, setAnimationType] = useState('in');
  const [isVisible, setIsVisible] = useState(false);
  const { rewardModalOpen, closeRewardModal, claimReward } = useReward();
  const { userAttributes } = useAuth();
  const { walletAddress, connectWallet, disconnectWallet } = useContext(WalletContext);
  const [selectedPayment, setSelectedPayment] = useState('stripe');

  useEffect(() => {
    if (rewardModalOpen) {
      setIsVisible(true);
      setAnimationType('in');
    } else if (!rewardModalOpen && isVisible) {
      setAnimationType('out');
      setTimeout(() => setIsVisible(false), 300);
    }
  }, [rewardModalOpen, isVisible]);

  if (!isVisible) return null;

  const handleClose = () => {
    setAnimationType('out');
    setTimeout(() => {
      closeRewardModal();
    }, 300);
  };

  const handleStripeInit = async () => {
    try {
      const data = await createStripeAccount();
      if (data) {
        window
          .open(data.url, '_blank')
          .focus();
      } else {
        console.error('Error creating stripe account');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClaimReward = async (selectedPayment) => {
    try {
      await claimReward(selectedPayment);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Background animationType={animationType}>
      <Modal animationType={animationType}>
        <ModalHeader>
          <h3>Select Payment Method</h3>
          <CloseButton onClick={handleClose}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M18.3 5.71a1 1 0 0 0-1.42 0L12 10.59 7.12 5.71A1 1 0 0 0 5.7 7.12L10.59 12l-4.88 4.88a1 1 0 1 0 1.42 1.42L12 13.41l4.88 4.88a1 1 0 0 0 1.42-1.42L13.41 12l4.88-4.88a1 1 0 0 0 0-1.41z" />
            </svg>
          </CloseButton>
        </ModalHeader>
        <ModalBody>
          <p>Choose how you want to receive your reward:</p>
          <ButtonContainer>
            <PaymentSelection>
              <p>Bank Account via Stripe</p>
              <SelectPaymentButton selected={selectedPayment === 'stripe'} onClick={() => setSelectedPayment('stripe')}>
                {selectedPayment === 'stripe' ? 'Selected' : 'Select'}
              </SelectPaymentButton>
            </PaymentSelection>
            <Divider />
            <PaymentSelection>
              <p>CGDX - CognideX Token</p>
              <SelectPaymentButton selected={selectedPayment === 'cgdx'} onClick={() => setSelectedPayment('cgdx')}>
                {selectedPayment === 'cgdx' ? 'Selected' : 'Select'}
              </SelectPaymentButton>
            </PaymentSelection>
            <Divider />
          </ButtonContainer>
        </ModalBody>
        <ModalEnd>
          {userAttributes['custom:stripe_account_id'] && selectedPayment === 'stripe' &&
            <MainButtonInvert
              header={userAttributes?.['custom:stripe_account_id'] ? 'Stripe Connected' : 'Connect Stripe'}
              subheader={userAttributes?.['custom:stripe_account_id'] ? 'Nice! You are connected to Stripe' : 'Click to connect to Stripe'}
              onClick={userAttributes?.['custom:stripe_account_id'] ? () => { } : handleStripeInit}
              active={userAttributes?.['custom:stripe_account_id']}
            />
          }
          {selectedPayment === 'cgdx' &&
            <MainButtonInvert
              header={walletAddress ? 'Wallet Connected' : 'Connect Wallet'}
              subheader={walletAddress ? 'Click to disconnect' : 'Connect your wallet'}
              onClick={walletAddress ? disconnectWallet : connectWallet}
              active={walletAddress}
            />
          }
          <MainButtonInvert header='Confirm' subheader='Get your reward' onClick={() => handleClaimReward(selectedPayment)} />
        </ModalEnd>
      </Modal>
    </Background >
  );
};

export default PaymentSelectionModal;
