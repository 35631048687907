import { post } from "aws-amplify/api";
import { fetchUserAttributes } from 'aws-amplify/auth';

export const createStripeAccount = async () => {
    try {
        const user = await fetchUserAttributes();
        const { body } = await post({
            apiName: 'stripeApi',
            path: '/stripe/create-stripe-account',
            options: {
                body: {
                    email: user.email,
                    userSub: user.sub
                }
            }
        }).response;

        const data = await body.json();

        return data;
    } catch (error) {
        console.error("Error:", error);
        return null
    }
}

